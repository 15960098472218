import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Text, Link, Image, Button } from '@chakra-ui/react';
import { FiDownload } from 'react-icons/fi'; // Importa o ícone de download

interface AppItem {
  id: number;
  nome: string;
  descricao: string;
  link: string;
  imagem?: string;
}

const ResidentApps: React.FC = () => {
  const [apps, setApps] = useState<AppItem[]>([]);

  useEffect(() => {
    // Substitua pela chamada à API para buscar os dados dos aplicativos
    setApps([
      {
        id: 1,
        nome: 'App de Segurança',
        descricao: 'Por meio do aplicativo, é possível saber o status do painel de alarme, armá-lo e desarmá-lo, realizar acionamentos de dispositivos, visualizar as câmeras ao vivo, verificar eventos e ordens de serviço abertas.',
        link: 'https://play.google.com/store/apps/details?id=br.com.segware.mysecurity.teleguardian&hl=pt&gl=US',
        imagem: 'https://www.teleguardian.com.br/images/logo.png'
      },
      {
        id: 2,
        nome: 'App Administrativo',
        descricao: 'Aplicativo para condomínios feito para proporcionar uma nova relação das pessoas com suas casas, condomínio e vizinhança.',
        link: 'https://play.google.com/store/apps/details?id=app.gruvi&pcampaignid=web_share',
        imagem: 'https://play-lh.googleusercontent.com/PLG78ilalZxse1e2Dv-R1pAw0cnsP18G1Q1XvyCELfeLf5CGyga3FUn37lpGxhcaY6ZZ'
      },
      {
        id: 3,
        nome: 'App Gás',
        descricao: 'Somos o primeiro aplicativo especialmente para você. Temos tudo que você precisa em uma tela simples.',
        link: 'https://play.google.com/store/apps/details?id=com.consigaz.areacliente&hl=pt_BR',
        imagem: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcmVaY8OtsXzQ2ev_qGvvr8qDyOlC3bQQpfQ&s'
      }
    ]);
  }, []);

  return (
    <Box p={5} pt="100px" pb="100px" maxWidth="1000px" mx="auto" textAlign="center">
      <Heading mb={8} fontFamily="'Montserrat', sans-serif" fontWeight="600">
        Aplicativos do Morador
      </Heading>
      <List spacing={8}>  {/* Maior espaçamento com base na sequência de Fibonacci */}
        {apps.map((app) => (
          <ListItem 
            key={app.id} 
            display="flex" 
            flexDirection={{ base: 'column', md: 'row' }} 
            alignItems="center" 
            justifyContent="space-between"
            mb={6}  // Margem inferior ajustada conforme a sequência de Fibonacci
            textAlign={{ base: 'center', md: 'left' }}
          >
            {/* Imagem do App */}
            <Image 
              src={app.imagem} 
              alt={app.nome} 
              boxSize={{ base: "100px", md: "120px" }}  // Imagem maior no mobile, médio no desktop
              mb={{ base: 4, md: 0 }}  // Margem inferior no mobile
              mr={{ base: 0, md: 8 }}  // Margem direita no desktop para afastar o texto
              mx="auto"  // Centralizar a imagem no mobile
              borderRadius="8px"
              boxShadow="lg"
            />

            {/* Texto e Botão */}
            <Box textAlign={{ base: "center", md: "left" }} flex="1">
              <Heading fontSize={{ base: "lg", md: "xl" }} mb={3} fontFamily="'Montserrat', sans-serif">
                {app.nome}
              </Heading>
              <Text 
                fontFamily="'Montserrat', sans-serif" 
                mb={4} 
                fontSize={{ base: "sm", md: "md" }} 
                lineHeight="1.6"
              >
                {app.descricao}
              </Text>

              {/* Botão "Baixar" na versão Web */}
              <Button 
                as={Link} 
                href={app.link} 
                colorScheme="teal" 
                size="md" 
                mt={2} 
                rightIcon={<FiDownload />} 
                isExternal 
                fontFamily="'Montserrat', sans-serif"
              >
                Baixar
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default ResidentApps;
