import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Header from './components/Hearder';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Regras from './pages/Regras';
import Contatos from './pages/Contatos';
import Footer from './components/Footer';
import NovoMorador from './pages/NovoMorador';
import ResidentApps from './pages/ResidentApps';
const App: React.FC = () => {
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <Router>
      <Box>
        <Header />
        <Box mt="100px" p="20px" overflow="hidden">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/ResidentApps" element={<ResidentApps />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/regras" element={<Regras />} />
            <Route path="/NovoMorador" element={<NovoMorador />} />
            <Route path="/contatos" element={<Contatos />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
};

export default App;



