import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';

const Contacts: React.FC = () => {
  return (
    <Box
      paddingTop="20px"
      paddingBottom="40px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="70vh"
      backgroundColor="gray.50"
      backgroundImage="url('/assets/images/contact-background.jpg')"
      backgroundSize="cover"
      backgroundPosition="center"
      textAlign="center"
    >
      <Stack spacing={6} maxWidth="600px" padding="20px" backgroundColor="white" borderRadius="md" boxShadow="lg">
        <Text fontSize="2xl" fontWeight="bold" color="gray.800" fontFamily="'Montserrat', sans-serif">
          Entre em Contato Conosco
        </Text>
        <Text fontSize="lg" color="gray.700" fontFamily="'Montserrat', sans-serif">
          Portaria: <Text as="span" fontWeight="bold" color="gray.800"> 0800 999 0555</Text>
        </Text>
        <Text fontSize="lg" color="gray.700" fontFamily="'Montserrat', sans-serif">
          Administradora: <Text as="span" fontWeight="bold" color="gray.800"> 35 3015-0097</Text>
        </Text>
        <Text fontSize="lg" color="gray.700" fontFamily="'Montserrat', sans-serif">
          Boletos: <Text as="span" fontWeight="bold" color="gray.800"> 0800 000 1661</Text>
        </Text>
      </Stack>
    </Box>
  );
}

export default Contacts;
