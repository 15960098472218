import React from 'react';
import { Box, Flex, Image, List, ListItem, IconButton, useDisclosure, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Header: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItems = [
    { name: 'Home', path: 'home' },
    { name: 'Sobre', path: 'sobre' },
    { name: 'Regras', path: 'regras' },
    { name: 'Novo Morador', path: 'NovoMorador' }, 
    { name: 'Aplicativos do Morador', path: 'ResidentApps' },
    { name: 'Contatos', path: 'contatos' }
  ];

  return (
    <Box
      overflow="hidden"
      as="header"
      bg="#F2F0D5"
      py={4}
      px={8}
      borderBottom="1px solid #D9BB25"
      position="fixed"
      top="0"
      width="100%"
      zIndex="1000"
      fontFamily="'Montserrat',sans-serif "
    >
      <Flex direction="column" align="center">
      <RouterLink to="/">
        <Image 
          src={`${process.env.PUBLIC_URL}/assets/images/logo.webp`} 
          alt="Portal Recanto das Camomilas" 
          borderRadius="1rem" 
          maxWidth="89px"
          mb={2}
          cursor="pointer"
        />
      </RouterLink>
        <Box display={{ base: 'block', md: 'none' }} textAlign="center">
          <IconButton
            aria-label="Open menu"
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={isOpen ? onClose : onOpen}
            variant="outline"
            borderColor="#D9BB25"
            color="#262626"
          />
          <Text mt={1} fontSize="sm" fontFamily="'Montserrat',sans-serif " fontWeight="bold" color="#262626">
            Menu
          </Text>
        </Box>
        <Box display={{ base: 'none', md: 'block' }}>
          <List display="flex" justifyContent="center" gap="20px" styleType="none">
            {menuItems.map((item) => (
              <ListItem key={item.path}>
                <RouterLink to={`/${item.path}`} style={{ fontWeight: 'bold', color: '#262626' }}>
                  {item.name}
                </RouterLink>
              </ListItem>
            ))}
          </List>
        </Box>
      </Flex>
      {isOpen && (
        <Box pb={4} display={{ md: 'none' }}>
          <List display="flex" flexDirection="column" alignItems="center" gap="10px" styleType="none">
            {menuItems.map((item) => (
              <ListItem key={item.path}>
                <RouterLink to={`/${item.path}`} style={{ fontWeight: 'bold', color: '#262626' }}>
                  {item.name}
                </RouterLink>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Header;

