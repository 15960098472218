import React from 'react';
import { Box, Heading, Text, Stack, List, ListItem } from '@chakra-ui/react';

const NovoMorador: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {/* Conteúdo principal */}
      <Box flex="1" p={5} maxWidth="800px" mx="auto" pt="100px" pb="100px">
        <Heading mb={5} fontFamily="'Montserrat', sans-serif" fontWeight="700" color="teal.600">
          Bem-vindo, Novo Morador!
        </Heading>
        <Text mb={8} fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" fontSize="lg">
          Abaixo estão algumas instruções e informações importantes para você se familiarizar com o condomínio e a comunidade.
        </Text>
        
        {/* Stack para seções com espaçamento */}
        <Stack spacing={10}>
          <Box>
            <Heading size="md" mb={3} fontFamily="'Montserrat', sans-serif" fontWeight="600" color="teal.500">
              Regras Gerais
            </Heading>
            <List spacing={2} pl={4} styleType="disc" color="gray.700">
              <ListItem>Respeite as áreas comuns e mantenha-as limpas.</ListItem>
              <ListItem>Evite fazer barulho excessivo após as 22h.</ListItem>
              <ListItem>Os animais de estimação devem estar sempre sob supervisão e em áreas permitidas.</ListItem>
            </List>
          </Box>

          <Box>
            <Heading size="md" mb={3} fontFamily="'Montserrat', sans-serif" fontWeight="600" color="teal.500">
              Serviços Disponíveis
            </Heading>
            <List spacing={2} pl={4} styleType="disc" color="gray.700">
              <ListItem>Mercado do Condominio: Em breve</ListItem>
              <ListItem>Piscina: Disponível das 8h às 22h. Uso permitido apenas para moradores e convidados.</ListItem>
              <ListItem>Salão de festas: Reservas devem ser feitas com antecedência na administração.</ListItem>
            </List>
          </Box>

          <Box>
            <Heading size="md" mb={3} fontFamily="'Montserrat', sans-serif" fontWeight="600" color="teal.500">
              Contato da Administração
            </Heading>
            <Text fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" fontSize="md">
              Para qualquer dúvida ou necessidade, entre em contato com a administração do condomínio através do telefone (35) 3015-0097
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default NovoMorador;


