import React from 'react';
import Slider from 'react-slick';
import { Box, Image, Text } from '@chakra-ui/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SimpleSlider: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    cssEase: 'linear',
    pauseOnHover: false,
    adaptiveHeight: true,
  };

  return (
    <Box width="100vw" overflow="hidden">
      <Slider {...settings}>
        <Box width="100vw" height="100vh" position="relative">
          <Image 
            src="/assets/images/img1.webp" 
            alt="Slide 1" 
            objectFit="cover" 
            width="100%" 
            height="100%" 
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
            textAlign="center"
            p="2"
            borderRadius="md"
          >
            <Text fontSize="5xl" fontWeight="medium">Espaços Planejados</Text>
          </Box>
        </Box>
        <Box width="100vw" height="100vh" position="relative">
          <Image 
            src="/assets/images/img2.webp" 
            alt="Slide 2" 
            objectFit="cover" 
            width="100%" 
            height="100%" 
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
            textAlign="center"
            p="2"
            borderRadius="md"
          >
            <Text fontSize="5xl" fontWeight="medium">Piscinas</Text>
          </Box>
        </Box>
        <Box width="100vw" height="100vh" position="relative">
          <Image 
            src="/assets/images/img3.webp" 
            alt="Slide 3" 
            objectFit="cover" 
            width="100%" 
            height="100%" 
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
            textAlign="center"
            p="2"
            borderRadius="md"
          >
            <Text fontSize="5xl" fontWeight="medium">Happy Hour</Text>
          </Box>
        </Box>
      </Slider>
    </Box>
  );
};

export default SimpleSlider;
