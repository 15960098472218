import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem, Divider } from '@chakra-ui/react';

const rules = [
  {
    id: 1,
    titulo: "Direitos",
    descricao: "Cada condômino tem o direito de usar as áreas comuns e sua unidade de forma respeitosa e sem causar danos aos outros."
  },
  {
    id: 2,
    titulo: "Deveres",
    descricao: "Manter a boa convivência, respeitar o horário de silêncio das 22:00 às 08:00 e contribuir com as despesas do condomínio."
  },
  {
    id: 3,
    titulo: "Proibições",
    descricao: "É proibido realizar festas ou reuniões sem aviso prévio e utilizar as áreas comuns para fins comerciais."
  },
  {
    id: 4,
    titulo: "Vagas de Estacionamento",
    descricao: "Cada unidade tem direito ao uso exclusivo de uma vaga e não pode ceder ou vender a vaga separadamente da unidade."
  },
  {
    id: 5,
    titulo: "Áreas de Lazer",
    descricao: "As áreas de lazer são de uso comum, respeitando as regras de reserva e limpeza após o uso."
  }
];

const Rules: React.FC = () => {
  return (
    <Box p={8} maxWidth="800px" mx="auto" textAlign="center" paddingTop="100px">
      <Heading 
        fontFamily="'Montserrat', sans-serif" 
        fontWeight="600" 
        fontSize="3xl" 
        mb={8} 
        color="teal.600"
        borderBottom="2px solid teal"
        pb={4}
      >
        Regras do Condomínio
      </Heading>
      <UnorderedList spacing={8} styleType="none" margin={0}>
        {rules.map((rule) => (
          <ListItem key={rule.id}>
            <Text 
              fontFamily="'Montserrat', sans-serif" 
              fontWeight="500" 
              fontSize="lg" 
              color="gray.700"
            >
              <Text as="span" color="teal.500" fontWeight="700">{rule.titulo}:</Text> {rule.descricao || "Sem descrição"}
            </Text>
            <Divider my={6} borderColor="gray.300" />
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
}

export default Rules;


