import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Footer: React.FC = () => {
  return (
    <Box
     
      as="footer"
      bg="#F2F0D5"
      py={4}
      textAlign="center"
      borderTop="1px solid #D9BB25"
      color="#262626"
      position="fixed"
      bottom="0"
      width="100%"
    >
      <Text>© 2024 Portal Recanto das Camomilas</Text>
    </Box>
  );
};

export default Footer;

