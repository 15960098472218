import React from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import { MdPlayArrow } from 'react-icons/md';

const Hero: React.FC = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      backgroundImage="url('/assets/images/capa.webp')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="white"
      textAlign="center"
      position="relative"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <Box zIndex={1}  p="20px" >
        <Heading 
          fontSize="6xl" 
          fontWeight="300" 
          fontFamily="'Montserrat', sans-serif"
          mb="8"
        >
          Portal Recanto das Camomilas
        </Heading>
        <Flex
          as="div"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => { window.location.href = 'https://www.youtube.com/watch?v=Yhw46EpvfUE'; }}
        >
          <MdPlayArrow size="2em" color="white" style={{ marginRight: '0.5em' }} />
          <Text 
            fontSize="lg" 
            fontFamily="'Montserrat', sans-serif"
            position="relative"
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '2px',
              backgroundColor: 'white',
              bottom: '-5px',
              left: 0,
              transform: 'scaleX(0)',
              transformOrigin: 'bottom right',
              transition: 'transform 0.3s ease-in-out',
            }}
            _hover={{
              _after: {
                transform: 'scaleX(1)',
                transformOrigin: 'bottom left',
              },
            }}
          >
            Assista ao vídeo
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default Hero;






