import React from 'react';
import { Box } from '@chakra-ui/react';
import Hero from '../components/Hero';
import SimpleSlider from '../components/SimpleSlider';

const Home: React.FC = () => {
  return (
    <Box width="100vw" maxWidth="100vw" overflow="hidden" marginLeft={{ base: '-4.8vw', md: '-1.9vw' }} >
      <Hero />
      <Box  >
        <SimpleSlider />
      </Box>
    </Box>
  );
}

export default Home;









