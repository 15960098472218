import React from 'react';
import { Box, Heading, Text, Divider } from '@chakra-ui/react';

const About: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" bg="gray.50">
      <Box flex="1" p={5} maxWidth="800px" mx="auto" paddingTop="80px" pb="100px"> {/* Adiciona pb="100px" */}
        <Heading mb={5} fontFamily="'Montserrat', sans-serif" fontWeight="700" fontSize="2xl">
          Sobre
        </Heading>
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" mb={4}>
          <strong>Condomínio Recanto das Camomilas: Um Oásis de Tranquilidade e Conforto</strong>
        </Text>
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" mb={4}>
          Bem-vindo ao <strong>Condomínio Recanto das Camomilas</strong>, um verdadeiro refúgio para aqueles que buscam qualidade de vida, segurança e um ambiente acolhedor. Localizado em uma área privilegiada, o Recanto das Camomilas combina o charme da natureza com o conforto moderno, oferecendo uma experiência de moradia única.
        </Text>
        <Divider my={6} />
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="600" color="teal.500" mb={3}>
          Uma Comunidade Bem Planejada
        </Text>
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" mb={4}>
          O Recanto das Camomilas foi cuidadosamente planejado para proporcionar o máximo de conforto e conveniência aos seus moradores. Com ruas arborizadas, áreas de lazer completas e espaços comuns bem cuidados, o condomínio é ideal para famílias, casais e indivíduos que valorizam um estilo de vida tranquilo e seguro.
        </Text>
        <Divider my={6} />
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="600" color="teal.500" mb={3}>
          Infraestrutura Completa
        </Text>
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" mb={4}>
          O condomínio conta com uma infraestrutura completa que inclui:
        </Text>
        <Text as="ul" fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" mb={4} pl={4}>
          <li>Área de Lazer: Piscinas, playgrounds, quadras poliesportivas e academias ao ar livre, proporcionando opções de lazer e atividades físicas para todas as idades.</li>
          <li>Segurança: Sistema de segurança 24 horas com portaria monitorada, garantindo a tranquilidade e a proteção dos moradores.</li>
          <li>Espaços Verdes: Jardins e áreas verdes planejadas, onde você pode relaxar e se conectar com a natureza.</li>
        </Text>
        <Divider my={6} />
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="600" color="teal.500" mb={3}>
          Um Lugar Para Chamar de Lar
        </Text>
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600" mb={4}>
          No Recanto das Camomilas, cada detalhe foi pensado para criar um ambiente de convivência harmonioso e agradável. Com opções de residências que vão desde apartamentos aconchegantes até casas espaçosas, o condomínio atende a diferentes necessidades e estilos de vida, mantendo sempre a qualidade e o conforto como prioridade.
        </Text>
        <Divider my={6} />
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="600" color="teal.500" mb={3}>
          Venha Conhecer!
        </Text>
        <Text fontFamily="'Montserrat', sans-serif" fontWeight="400" color="gray.600">
          Descubra o encanto do Condomínio Recanto das Camomilas e faça parte de uma comunidade que valoriza a qualidade de vida, o bem-estar e a tranquilidade. Agende uma visita e experimente em primeira mão o que faz deste lugar um verdadeiro lar.
        </Text>
      </Box>
    </Box>
  );
}

export default About;






